.loaderWrap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1050;
  display: none;
}
.loaderWrap .loader {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -100px;
  display: flex;
  flex-direction: column;
}
.spinner {
    display: inline-block;
    width: 300px;
    height: 80px;
    margin: 0 auto;
}

.spinner .shp0 { fill: none;stroke: #FCDE51;paint-order:stroke fill markers;stroke-width: 3.5;
	-webkit-animation:spin 3s linear infinite;
	-moz-animation:spin 3s linear infinite;
	animation:spin 3s linear infinite;
	transform-origin: 50% 50%;
}
.spinner .shp0.spin2 {
	-webkit-animation:spin2 3s linear infinite;
	-moz-animation:spin2 3s linear infinite;
	animation:spin2 3s linear infinite;
	transform-origin: 50% 50%;
}
.spinner .shp2 { fill: #ff0000 }

@keyframes spin {
	0% { -webkit-transform: rotate(90deg); transform:rotate(90deg); }
	5% { -webkit-transform: rotate(100deg); transform:rotate(100deg); }
	10% { -webkit-transform: rotate(120deg); transform:rotate(120deg); }
	15% { -webkit-transform: rotate(180deg); transform:rotate(180deg); }
	33% { -webkit-transform: rotate(180deg); transform:rotate(180deg); }
	43% { -webkit-transform: rotate(270deg); transform:rotate(270deg); }
	62% { -webkit-transform: rotate(270deg); transform:rotate(270deg); }
	68% { -webkit-transform: rotate(360deg); transform:rotate(360deg); }
	85% { -webkit-transform: rotate(450deg); transform:rotate(450deg); }
	100% { -webkit-transform: rotate(450deg); transform:rotate(450deg); }
}
@keyframes spin2 {
	0% { -webkit-transform: rotate(90deg); transform:rotate(90deg); }
	15% { -webkit-transform: rotate(90deg); transform:rotate(90deg); }
	19% { -webkit-transform: rotate(180deg); transform:rotate(180deg); }
	33% { -webkit-transform: rotate(270deg); transform:rotate(270deg); }
	45% { -webkit-transform: rotate(270deg); transform:rotate(270deg); }
	50% { -webkit-transform: rotate(280deg); transform:rotate(280deg); }
	54% { -webkit-transform: rotate(300deg); transform:rotate(300deg); }
	62% { -webkit-transform: rotate(360deg); transform:rotate(360deg); }
	85% { -webkit-transform: rotate(360deg); transform:rotate(360deg); }
	98% { -webkit-transform: rotate(450deg); transform:rotate(450deg); }
	100% { -webkit-transform: rotate(450deg); transform:rotate(450deg); }
}
